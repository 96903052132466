import * as React from 'react'
import User from '../Types/User'
import AuthenticationServiceInterface from '../Types/AuthenticationServiceInterface'

interface UserStateInterface {
  user?: User;
}

const UserContext = React.createContext<UserStateInterface>({})

export const createUserProvider = (authenticationService: AuthenticationServiceInterface) => (props) => {
  const [user, setUser] = React.useState<User | undefined>(undefined)

  React.useEffect(() => {
    authenticationService.getCurrentUser().then(setUser)

    return authenticationService.onUserAuthenticationChange(setUser)
  }, [])
  const AuthServiceContainer = authenticationService.getContainer()
  return (
    <AuthServiceContainer>
      <UserContext.Provider value={{ user }}>
        {props.children}
      </UserContext.Provider>
    </AuthServiceContainer>
  )
}

export const useUser = () => {
  const context = React.useContext(UserContext)

  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component')
  }
  return context
}
