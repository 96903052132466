import * as React from 'react'
import { Button } from '@material-ui/core'
import AuthenticationProps from '../../../Types/AuthenticationProps'

export default function LogoutButton({ authenticationService }: AuthenticationProps) {
  function logout() {
    return authenticationService.logout()
  }

  return (
    <Button variant="contained" color="primary" onClick={logout}>Logout</Button>
  )
}
