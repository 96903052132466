// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-breweries-add-tsx": () => import("./../../../src/pages/breweries/add.tsx" /* webpackChunkName: "component---src-pages-breweries-add-tsx" */),
  "component---src-pages-breweries-index-tsx": () => import("./../../../src/pages/breweries/index.tsx" /* webpackChunkName: "component---src-pages-breweries-index-tsx" */),
  "component---src-pages-breweries-map-tsx": () => import("./../../../src/pages/breweries/map.tsx" /* webpackChunkName: "component---src-pages-breweries-map-tsx" */),
  "component---src-pages-brewery-tsx": () => import("./../../../src/pages/brewery.tsx" /* webpackChunkName: "component---src-pages-brewery-tsx" */),
  "component---src-pages-homepage-tsx": () => import("./../../../src/pages/Homepage.tsx" /* webpackChunkName: "component---src-pages-homepage-tsx" */),
  "component---src-pages-import-tsx": () => import("./../../../src/pages/Import.tsx" /* webpackChunkName: "component---src-pages-import-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */)
}

