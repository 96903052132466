import * as React from 'react'
import AuthenticationService from './Services/AuthenticationService'
import { createUserProvider } from './Services/UserContext'
import LoginButtonInternal from './UseCases/Login/Components/LoginButton'
import LogoutButtonInternal from './UseCases/Logout/Components/LogoutButton'

const authService = new AuthenticationService()

export const UserProvider = createUserProvider(authService)
const AuthenticatorComponent = authService.getAuthenticatorComponent()
export const LoginButton = () => <LoginButtonInternal><AuthenticatorComponent/></LoginButtonInternal>
export const LogoutButton = () => <LogoutButtonInternal authenticationService={authService}/>
