import { load } from 'redux-localstorage-simple'
import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './counterSlice'
import breweriesReducer, { Brewery } from './breweriesSlice'
import { GenericState } from './GenericSlice'


export interface IState {
  breweries: GenericState<Brewery>
}

export default (preloadedState: IState) => {
  return configureStore({
    reducer: {
      counter: counterReducer,
      breweries: breweriesReducer,
    },
  })
};

const getLoadedState = (preloadedState: IState | any) => {
  if (typeof window !== 'undefined')
    return {
      ...preloadedState,
      ...load({ states: ['loginReducer'], disableWarnings: true }),
    }

  return {
    ...preloadedState,
  }
}
