import * as React from 'react'
import { Auth } from 'aws-amplify'
import AuthenticationServiceInterface from '../Types/AuthenticationServiceInterface'
import User from '../Types/User'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { AmplifyAuthenticator, AmplifyContainer } from '@aws-amplify/ui-react'

function transformCognitoUser(cognitoUser): User | undefined {
  if (cognitoUser?.attributes?.email) {
    return {
      email: cognitoUser?.attributes?.email,
    }
  } else {
    return undefined
  }
}

export default class AuthenticationService implements AuthenticationServiceInterface {
  logout(): Promise<void> {
    return Auth.signOut()
  }

  onUserAuthenticationChange(onChange: (user?: User) => void): () => void {
    return onAuthUIStateChange((nextAuthState, authData) => {
      const user = transformCognitoUser(authData)
      onChange(user)
    })
  }

  getCurrentUser(): Promise<User> {
    return Auth.currentUserInfo()
  }

  getContainer(): React.ReactNode {
    return AmplifyContainer
  }

  getAuthenticatorComponent(): React.ReactNode {
    return AmplifyAuthenticator
  }
}
