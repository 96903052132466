import * as React from 'react'
import { Button, Dialog } from '@material-ui/core'

export default function LoginButton(props) {
  const [isOpen, setOpen] = React.useState(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>Login</Button>
      <Dialog onClose={handleClose} open={isOpen}>
        {props.children}
      </Dialog>
    </>
  )
}
