import { createAsyncThunk } from '@reduxjs/toolkit'
import { ListBreweriesOptions } from '../api/OpenBreweryDBAPI'
import { createGenericSlice, GenericState } from './GenericSlice'
import { API, graphqlOperation } from 'aws-amplify'
import { listBrewerys, nearbyBreweries } from '../graphql/queries'
import { ICoordinates } from '../models/brewery'

export type Brewery = any

const MAX_PER_PAGE = 1000

type StringSearch = {
  contains: string;
}

interface FetchBreweriesByName {
  name: StringSearch
}

interface FetchNearbyBreweries {
  coordinates: ICoordinates,
  km: number
}

type FetchBreweriesOptions = FetchBreweriesByName | FetchNearbyBreweries

export const fetchBreweries = createAsyncThunk(
  'breweries/search',
  (filters: FetchBreweriesOptions) => {
    return (API.graphql(graphqlOperation(listBrewerys, {
        filter: filters,
        limit: MAX_PER_PAGE,
      })).then(({ data: { listBrewerys: { items, nextToken } } }) => ({
        data: items,
        requestParams: {
          nextToken,
        },
      }))
    )
  },
)

export const fetchMoreBreweries = createAsyncThunk(
  'breweries/searchMore',
  (options: ListBreweriesOptions, { getState }) => {
    const { breweries: { requestParams } } = getState()
    return (API.graphql(graphqlOperation(listBrewerys, {
        limit: MAX_PER_PAGE,
        ...requestParams,
      })).then(({ data: { listBrewerys: { items, nextToken } } }) => ({
        data: items,
        requestParams: {
          nextToken,
        },
      }))
    )
  },
)

export const fetchNearbyBreweries = createAsyncThunk(
  'breweries/nearbyBreweries',
  (options: FetchNearbyBreweries, { getState }) => {
    const { breweries: { requestParams } } = getState()
    return (API.graphql(graphqlOperation(nearbyBreweries, {
        input: {
          ...options,
          coordinates: {
            lat: options.coordinates.lat,
            lon: options.coordinates.lng,
          },
          limit: MAX_PER_PAGE,
        },
      }))
        .then(({
                 data: {
                   nearbyBreweries: {
                     items,
                     nextToken,
                   },
                 },
               }) => ({
          data: items.map(({ brewery, distance }) => ({
            ...brewery, coordinates: {
              lat: brewery.coordinates.lat,
              lng: brewery.coordinates.lon,
            }, distance,
          })),
          requestParams: {
            nextToken,
          },
        }))
    )
  },
)

export const fetchBreweriesByName = (name: string) => fetchBreweries({ name: { contains: name } })
export const fetchBreweriesByLocation = (city: string) => fetchBreweries({ by_city: city })

export const breweriesSlice = createGenericSlice({
  name: 'brewery',
  initialState: {
    status: 'idle',
    data: [],
    hasNext: true,
    requestParams: {},
  } as GenericState<Brewery[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBreweries.pending, (state, { meta: { arg } }) => {
      return {
        data: [],
        status: 'loading',
        hasNext: true,
        requestParams: arg,
      }
    })
    builder.addCase(fetchBreweries.fulfilled, (state, { payload }) => {
      return {
        status: 'finished',
        hasNext: payload.data.length === MAX_PER_PAGE,
        ...payload,
      }
    })
    builder.addCase(fetchMoreBreweries.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.hasNext = payload.data.length === MAX_PER_PAGE
      state.requestParams = payload.requestParams
      state.data = [...state.data, ...payload.data]
    })
    builder.addCase(fetchBreweries.rejected, (state) => {
      return {
        ...state,
        status: 'error',
      }
    })
    builder.addCase(fetchNearbyBreweries.pending, (state, { meta: { arg } }) => {
      return {
        data: [],
        status: 'loading',
        hasNext: true,
        requestParams: arg,
      }
    })
    builder.addCase(fetchNearbyBreweries.fulfilled, (state, { payload }) => {
      return {
        status: 'finished',
        hasNext: payload.data.length === MAX_PER_PAGE,
        ...payload,
      }
    })
  },
})

export default breweriesSlice.reducer
