/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nearbyBreweries = /* GraphQL */ `
    query NearbyBreweries($input: NearbyBreweriesInput!) {
        nearbyBreweries(input: $input) {
            items {
                brewery {
                    id
                    name
                    type
                    phoneNumber
                    webpage
                    createdAt
                    updatedAt
                    coordinates {
                        lat,
                        lon
                    }
                }
                distance
            }
            total
            nextToken
        }
    }
`
export const getBrewery = /* GraphQL */ `
    query GetBrewery($id: ID!) {
        getBrewery(id: $id) {
            id
            name
            type
            phoneNumber
            webpage
            coordinates {
                lat
                lon
            }
            businessHours {
                day
                from {
                    hour
                    minutes
                }
                to {
                    hour
                    minutes
                }
            }
            address {
                line1
                line2
                line3
                city
                state
                country
                postalcode
            }
            createdAt
            updatedAt
        }
    }
`
export const listBrewerys = /* GraphQL */ `
    query ListBrewerys(
        $filter: ModelBreweryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listBrewerys(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                type
                phoneNumber
                webpage
                coordinates {
                    lat
                    lon
                }
                businessHours {
                    day
                }
                address {
                    line1
                    line2
                    line3
                    city
                    state
                    country
                    postalcode
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`
export const searchBrewerys = /* GraphQL */ `
    query SearchBrewerys(
        $filter: SearchableBreweryFilterInput
        $sort: SearchableBrewerySortInput
        $limit: Int
        $nextToken: String
        $from: Int
    ) {
        searchBrewerys(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            from: $from
        ) {
            items {
                id
                name
                type
                phoneNumber
                webpage
                coordinates {
                    lat
                    lon
                }
                businessHours {
                    day
                }
                address {
                    line1
                    line2
                    line3
                    city
                    state
                    country
                    postalcode
                }
                createdAt
                updatedAt
            }
            nextToken
            total
        }
    }
`
